<template>
  <button type="button" :disabled="isLocked"
    :class="isLocked ? 'disabled:opacity-75 cursor-not-allowed' : 'hover:bg-blue-700'"
    @click.prevent="showExtendServerRuntimeModal = true"
    class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 focus:outline-none">
    {{ $t('server.buttons.extend_server') }}
  </button>
  <extend-server-runtime-modal :isOpen="showExtendServerRuntimeModal" @close="showExtendServerRuntimeModal = false"
    @extendSuccess="refreshServer" :server="server" />
</template>

<script>
import { mapGetters } from 'vuex'
import ExtendServerRuntimeModal from '@/components/service/modals/ExtendServerRuntimeModal'

export default {
  name: 'ExtendServerRuntimeButton',

  components: { ExtendServerRuntimeModal },

  computed: {
    ...mapGetters(['isLocked']),
  },

  props: {
    server: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      showExtendServerRuntimeModal: false,
    }
  },

  methods: {
    refreshServer() {
      this.showExtendServerRuntimeModal = false

      this.$emit('refreshServer')
    }
  },

  destroyed() {
    this.$destroy()
  }
}
</script>
