<template>
  <component v-if="!completed" :is="as" v-bind="$attrs">
    <slot :remainingTime="remainingTime"></slot>
  </component>
  <component v-else :is="as" v-bind="$attrs">
    <slot name="completed"></slot>
  </component>
</template>

<script>
import moment from "moment/moment";

export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    as: {
      type: String,
      default: 'span',
    },
  },
  data() {
    return {
      intervalId: null,
      remainingTime: null,
      completed: false,
    };
  },
  created() {
    this.calculateRemainingTime();
    this.intervalId = setInterval(() => {
      this.calculateRemainingTime();
    }, 1000);
  },
  methods: {
    calculateRemainingTime() {
      const targetTime = moment.utc(this.to);
      const currentTime = moment.utc();
      const timeDiff = Math.abs(currentTime.diff(targetTime));

      if (currentTime.isAfter(targetTime)) {
        this.remainingTime = '00:00:00:00';
        this.completed = true;
        clearInterval(this.intervalId);
        this.$emit('completion');
      } else {
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        this.remainingTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      }
    }
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
};
</script>
