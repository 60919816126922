<template>
  <transition>
    <modal id="change-ip">
      <template v-slot:body>
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-blue-600 dark:text-blue-300" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd" fill-rule="evenodd"
                d="M13.2 2.24a.75.75 0 00.04 1.06l2.1 1.95H6.75a.75.75 0 000 1.5h8.59l-2.1 1.95a.75.75 0 101.02 1.1l3.5-3.25a.75.75 0 000-1.1l-3.5-3.25a.75.75 0 00-1.06.04zm-6.4 8a.75.75 0 00-1.06-.04l-3.5 3.25a.75.75 0 000 1.1l3.5 3.25a.75.75 0 101.02-1.1l-2.1-1.95h8.59a.75.75 0 000-1.5H4.66l2.1-1.95a.75.75 0 00.04-1.06z">
              </path>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100" id="modal-headline">
              {{ $t('ip.change_modal.change_ip') }}
            </h3>
            <div class="mt-2 text-sm text-gray-600 dark:text-gray-400">
              {{ $t('ip.change_modal.change_ip_text') }}
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="col-span-6">
                <div class="font-bold text-sm text-gray-500 dark:text-gray-100"
                  v-html="$t('ip.change_modal.important_information')"></div>
                <div class="text-sm text-gray-500 dark:text-gray-100 mt-2">
                  <ul class="list-disc list-inside">
                    <li>{{ $t('ip.change_modal.info_1') }}</li>
                    <li>{{ $t('ip.change_modal.info_2') }}</li>
                    <li>{{ $t('ip.change_modal.info_3') }}</li>
                    <li>{{ $t('ip.change_modal.info_4') }}</li>
                  </ul>
                </div>
              </div>

              <div class="col-span-3">
                <label for="country" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                  {{ $t('ip.change_modal.immediate_payment') }}
                </label>
                <div class="mt-1 text-xl font-semibold text-gray-700 dark:text-gray-300">
                  <template v-if="oneTimeFee && !isLoading">
                    {{ oneTimeFee }} &euro;
                  </template>
                  <template v-else-if="isLoading">
                    <div class="h-6 mt-2 w-20 bg-gray-300 rounded-md animate-pulse"></div>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="mt-1 text-xs text-gray-500 dark:text-gray-100">
                  {{ $t('ip.change_modal.next_payment') }}
                </div>
              </div>

              <div class="col-span-3">
                <label for="country" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                  {{ $t('ip.change_modal.current_ip') }}
                </label>
                <div class="mt-1 text-xl font-semibold text-gray-700 dark:text-gray-300">
                  <template v-if="currentIp && !isLoading">
                    {{ currentIp }}
                  </template>
                  <template v-else-if="isLoading">
                    <div class="h-6 mt-2 w-40 bg-gray-300 rounded-md animate-pulse"></div>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="mt-1 text-xs text-gray-500 dark:text-gray-100">
                  {{ $t('ip.change_modal.ip_replaced') }}
                </div>
              </div>

              <div class="col-span-6 border border-red-200 p-4 bg-red-50 rounded" v-if="isEnoughBalance">
                <div class="text-sm text-red-500">
                  <div class="flex items-center space-x-1">
                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"></path>
                    </svg>
                    <p class="font-medium">{{ $t('ip.change_modal.insufficient_balance') }}</p>
                  </div>
                  <div class="mt-1" v-html="$t('ip.change_modal.no_money', { oneTimeFee: oneTimeFee })">
                  </div>
                </div>
              </div>

              <div class="col-span-6 border border-red-200 p-4 bg-red-50 rounded" v-if="!changeable">
                <div class="text-sm text-red-500">
                  <div class="flex items-center space-x-1">
                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"></path>
                    </svg>
                    <p class="font-medium">{{ $t('ip.change_modal.not_changeable') }}</p>
                  </div>
                  <div class="mt-1"
                    v-html="changedTooOften ? $t('ip.change_modal.changed_too_often') : $t('ip.change_modal.default_error')">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:w-auto">
            <button-spinner :disabled="isLoading" :btnDisable="isEnoughBalance || !changeable" @click="submit(apiData)"
              class="disabled:opacity-75 focus:outline-none focus:ring-0 focus:ring-offset-0 bg-blue-600 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
              {{ $t('ip.change_modal.change', { oneTimeFee: oneTimeFee }) }}
            </button-spinner>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button @click.prevent="close" type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white dark:bg-gray-700 dark:hover:bg-gray-800 dark:border-gray-600 text-base leading-6 font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              {{ $t('ip.change_modal.cancel') }}
            </button>
          </span>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/modal/Modal'
import ButtonSpinner from '@/components/form/ButtonSpinner'
import Dinero from 'dinero.js'
import { getServerMainIpField } from '@/helpers/server';

export default {
  name: 'ChangeIpModal',
  props: {
    server: {
      required: true,
      type: Object
    }
  },

  components: {
    Modal,
    ButtonSpinner
  },

  computed: {
    ...mapGetters([
      'ipPricing',
      'ipPricingError',
      'isIpChanged',
      'ipChangeErrors'
    ]),
    ...mapGetters({ balance: 'getBalance' }),
    isEnoughBalance() {
      return this.balance.cents < this.ipPricing.price
    },
    changedTooOften() {
      return this.notChangeableReason.includes('changed-too-often')
    }
  },

  data() {
    return {
      isLoading: false,
      insufficientBalance: false,
      changeable: true,
      currentIp: "",
      notChangeableReason: "",
      oneTimeFee: 0,
      apiData: {},
    }
  },

  mounted() {
    this.apiData = {
      serverId: this.$route.params.id,
      ipAddressId: getServerMainIpField(this.server, 4, "id"),
    }
    this.fetchIpPrice(this.apiData)
    this.currentIp = getServerMainIpField(this.server, 4, "ip_address")
  },

  methods: {
    close() {
      this.$emit('close')
    },

    priceString(price) {
      price = parseFloat(price)
      price = price >= 0 ? price : 0

      return Dinero({ amount: price, currency: 'EUR' })
        .setLocale('de-DE')
        .toFormat('0,0.00')
    },

    submit(apiData) {
      this.isLoading = true

      this.$store.dispatch('changeIp', apiData)
    },

    fetchIpPrice(apiData) {
      this.isLoading = true
      this.ipAddressId = getServerMainIpField(this.server, 4, "id")

      if (apiData.serverId !== null && apiData.ipAddressId !== null) {
        this.$store.dispatch('fetchIpPrice', apiData).finally(() => {
          this.isLoading = false
        })
      }
    },
  },

  watch: {
    ipPricing(pricing) {
      this.changeable = pricing.changeable
      this.notChangeableReason = pricing.reason
      if (pricing) {
        this.oneTimeFee = this.priceString(pricing.price)
      }
    },

    isIpChanged(status) {
      if (status) {
        setTimeout(() => {
          this.isLoading = false
          this.$toast.success(this.$t('ip.change_modal.successful_change'))
          this.$emit('success')
        }, 4000)
      }
    },

    ipChangeErrors(errorData) {
      if (errorData && errorData?.length > 0) {
        this.isLoading = false
        this.$toast.error(this.$t('ip.change_modal.default_error'))
        this.$emit('close')
      }
    }
  }
}
</script>

<style>
#change-ip .modal-body {
  width: 630px;
}

#change-ip .modal-footer {
  padding: 0;
  border-top: none;
}
</style>
