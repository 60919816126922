<template>
  <router-link :to="this.to" type="button"
    class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none focus:ring-0 focus:ring-offset-0">
    <svg class="-ml-1 mr-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
        clip-rule="evenodd"></path>
    </svg>
    <template v-if="short">
      {{ $t('global.back') }}
    </template>
    <template v-else>
      {{ $t('commonGlobal.backToOverview') }}
    </template>
  </router-link>
</template>

<script>
export default {
  name: 'BackToOverviewButton',

  props: {
    to: {
      type: String,
      required: true
    },
    short: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>
