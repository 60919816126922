<template>
  <template v-for="announcement in lastAnnouncementWithHeaderBanner" :key="announcement.id">
    <div v-bind:class="getBannerClass(announcement)">
      <div class="max-w-screen-xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-1.5 rounded-lg bg-blue-800" v-if="announcement.type === 'info'">
              <svg class="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>
            <span class="flex p-1.5 rounded-lg bg-yellow-500" v-else-if="announcement.type === 'warning'">
              <svg class="h-5 w-5 text-yellow-900" aria-hidden="true" fill="none" stroke="currentColor" stroke-width="2"
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                  stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </span>
            <span class="flex p-1.5 rounded-lg bg-red-700" v-else-if="announcement.type === 'danger'">
              <svg class="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>
            <span class="flex p-1.5 rounded-lg bg-green-800" v-else>
              <svg class="h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>
            <p class="ml-3 font-medium text-sm truncate"
              :class="{ 'text-gray-800': announcement.type === 'warning', 'text-white': announcement.type === 'info' || announcement.type === 'danger' || announcement.type === 'success' }">
              <span class="md:hidden">
                {{ announcement.title }}
              </span>
              <span class="hidden md:inline">
                {{ announcement.title }}
              </span>
            </p>
          </div>
          <div class="order-2 flex-shrink-0 sm:mt-0 sm:w-auto">
            <router-link :to="`/announcement/${announcement.id}`" type="button"
              class="inline-flex items-center rounded border border-transparent px-2.5 py-1.5 text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
              :class="{
                'bg-yellow-100 text-yellow-700 hover:bg-yellow-200 focus:ring-yellow-500': announcement.type === 'warning',
                'bg-blue-100 text-blue-700 hover:bg-blue-200 focus:ring-blue-500': announcement.type === 'info',
                'bg-red-100 text-red-700 hover:bg-red-200 focus:ring-red-500': announcement.type === 'danger',
                'bg-green-100 text-green-700 hover:bg-green-200 focus:ring-green-500': announcement.type === 'success'
              }">
              {{ $t('global.learn_more') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AnnouncementBanner',

  computed: {
    ...mapGetters(['announcements']),

    lastAnnouncementWithHeaderBanner() {
      const withHeaderBanner = this.announcements ? this.announcements.filter(banner => banner.header_banner) : []

      if (withHeaderBanner.length) {
        return [withHeaderBanner[withHeaderBanner.length - 1]]
      }

      return []
    }
  },

  methods: {
    getBannerClass(item) {
      let className

      switch (item.type) {
        case 'info':
          className = 'bg-blue-600'
          break
        case 'warning':
          className = 'bg-yellow-400'
          break
        case 'danger':
          className = 'bg-red-600'
          break
        default:
          className = 'bg-green-600'
      }

      return className
    }
  }
}
</script>
