<template>
  <div>
    <navigation :customer="customer" :hasAnnouncements="!!hasNewAnnouncements" :balance="balance"></navigation>

    <VisibleWithinTime start="2023-11-23T23:00:00.000000Z" end="2023-11-27T00:00:00.000000Z">
      <div class="flex items-center justify-center bg-gray-900 px-6 py-2.5 sm:px-3.5">
        <p class="text-sm leading-6 text-white">
          <strong class="font-semibold">
            🔥&nbsp;{{ $t('server.special_offer.title') }}&nbsp;🔥
          </strong>
          <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx="1" cy="1" r="1" />
          </svg>
          <span v-html="$t('server.special_offer.subtitle')" />
        </p>
      </div>
    </VisibleWithinTime>

    <header class="bg-white shadow-sm dark:bg-gray-700">
      <slot name="header"></slot>
    </header>

    <main>
      <div class="max-w-7xl mx-auto py-6 px-6 md:px-8">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteProfileBanner from "@/components/banner/CompleteProfileBanner";
import Navigation from '@/components/layout/Navigation'
import VisibleWithinTime from "@/components/common/VisibleWithinTime.vue";

export default {
  name: 'AppLayout',
  inject: ['isDark'],
  components: { VisibleWithinTime, Navigation, CompleteProfileBanner },

  computed: {
    ...mapGetters({
      customer: 'customer',
      balance: 'getBalance',
      hasNewAnnouncements: 'hasNewAnnouncements'
    })
  },

  beforeCreate() {
    this.$store.dispatch('getAccountBalance')
    this.$store.dispatch('getCustomer')
  },

  destroyed() {
    this.$destroy()
  }
}
</script>
