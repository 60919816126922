<template>
  <nav :class="{ 'py-3': isActiveNext || isActivePrevious, 'py-5': !(isActiveNext || isActivePrevious) }"
    class="bg-white dark:bg-gray-700 px-4 flex items-center justify-between border-t border-gray-200 dark:border-gray-600 sm:px-6"
    aria-label="Pagination">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700 dark:text-gray-300">
        {{ $t('global.pagination.show') }}
        <span class="font-medium">{{ meta.from }}</span>
        {{ $t('global.pagination.to') }}
        <span class="font-medium">{{ meta.to }}</span>
        {{ $t('global.pagination.of') }}
        <span class="font-medium">{{ meta.total }}</span>
        {{ $t('global.pagination.results') }}
      </p>
    </div>
    <div v-if="isActiveNext || isActivePrevious" class="flex-1 flex justify-between sm:justify-end">
      <button-spinner :btnDisable="!isActivePrevious" hoverColor="hover:bg-gray-50" @click="$emit('previous')"
        class="disabled:opacity-75 relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-400 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700">
        {{ $t('global.pagination.previous') }}
      </button-spinner>
      <button-spinner :btnDisable="!isActiveNext" hoverColor="hover:bg-gray-50" @click="$emit('next')"
        class="disabled:opacity-75 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-400 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700">
        {{ $t('global.pagination.next') }}
      </button-spinner>
    </div>
  </nav>
</template>

<script>
import ButtonSpinner from '@/components/form/ButtonSpinner'

export default {
  name: 'Pagination',

  props: {
    meta: {
      type: Object,
      required: true
    },
    isActivePrevious: {
      type: Boolean,
      required: true
    },
    isActiveNext: {
      type: Boolean,
      required: true
    }
  },

  components: { ButtonSpinner }
}
</script>
