<template>
  <RadioGroup v-model="localValue" @update:modelValue="updateValue">
    <div class="divide-y divide-gray-200 dark:divide-gray-700">
      <RadioGroupOption v-for="option in options" :key="option.months" :value="option" v-slot="{ checked }">
        <div :class="[
          checked ? 'bg-blue-50 dark:bg-blue-600' : 'hover:bg-gray-50 dark:hover:bg-gray-700',
          'relative flex cursor-pointer p-4 focus:outline-none items-center justify-between'
        ]">
          <span class="flex items-center text-xs md:text-sm w-1/3">
            <span :class="[
              checked
                ? 'bg-blue-600 dark:bg-blue-900 border-transparent'
                : 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-400',
              'h-4 w-4 rounded-full border flex items-center justify-center mr-3'
            ]">
              <span class="rounded-full bg-white dark:bg-gray-200 w-1.5 h-1.5" v-if="checked" />
            </span>
            <RadioGroupLabel as="span"
              :class="checked ? 'text-blue-900 dark:text-white' : 'text-gray-900 dark:text-gray-300'" class="font-medium">
              {{ option.months }} {{ $t('server.extend_server_runtime_modal.' + (option.months === 1 ? 'month' :
                'months')) }}
            </RadioGroupLabel>
          </span>
          <div class="flex-grow flex justify-center items-center">
            <span class="text-xs md:text-sm font-medium"
              :class="checked ? 'text-blue-900 dark:text-white' : 'text-gray-900 dark:text-gray-300'">
              € {{ option.gross_money }}
            </span>
          </div>
          <div class="w-1/3 flex justify-end">
            <span v-if="option.discount_relative > 0"
              class="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 dark:bg-blue-200 text-blue-800">
              {{ $t('server.extend_server_runtime_modal.discount') }} {{ option.discount_relative }}%
            </span>
          </div>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script setup>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  options: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['update:modelValue'])
const localValue = ref(props.modelValue)

const updateValue = (value) => {
  localValue.value = value
}

watch(localValue, (newValue) => {
  emit('update:modelValue', newValue)
})
</script>
