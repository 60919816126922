<template>
  <transition>
    <modal id="create-port">
      <template v-slot:body>
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-blue-600 dark:text-blue-300" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z">
              </path>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full pr-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100" id="modal-headline">
              {{ $t('server.create_port_modal.title') }}
            </h3>
            <div class="mt-2 text-sm text-gray-600 dark:text-gray-300">
              {{ $t('server.create_port_modal.description') }}
            </div>

            <create-port-modal-loader v-if="isLoadingPools" />

            <div v-else-if="availablePools.length" class="mt-6">
              <div class="sm:col-span-3 mb-6">
                <label for="privateNetwork" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                  {{ $t('server.create_port_modal.private_network') }}
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <select id="privateNetwork" v-model="form.private_network"
                    class="appearance-none block w-full px-3 py-2 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md placeholder-gray-400 focus:invalid-red-500 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    :class="{ 'border-red-500': errors.private_network, 'focus:border-blue-300': !errors.private_network }">
                    <option v-for="pool in availablePools" :key="pool.uuid" :value="pool.uuid">
                      {{ pool.alias }} - {{ pool.network }}/{{ pool.network_length }}
                    </option>
                  </select>
                </div>
                <div class="mt-1 text-xs text-red-500" v-if="errors.private_network">
                  {{ errors.private_network[0] }}
                </div>
                <div v-else class="mt-1 text-xs text-gray-600 dark:text-gray-300">
                  {{ $t('server.create_port_modal.private_network_description') }}
                </div>
              </div>

              <div class="sm:col-span-3 mb-6">
                <div class="flex text-sm flex-col">
                  <span class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                    {{ $t('server.create_port_modal.ip_allocation_method') }}
                  </span>
                  <div class="sm:flex mt-2">
                    <div class="space-y-3">
                      <div class="relative flex items-start">
                        <div class="flex h-5 items-center">
                          <input id="automatic-port" aria-describedby="automatic-port-description" type="radio"
                            v-model="form.allocation_method" value="automatical"
                            class="h-4 w-4 border-gray-300 text-blue-600 dark:text-blue-600 focus:ring-0 focus:ring-offset-0">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="automatic-port-description" class="font-medium text-gray-700 dark:text-gray-300">
                            {{ $t('server.create_port_modal.ip_allocation_method_dynamic') }}
                          </label>
                          <p id="automatic-port-description" class="text-gray-500 dark:text-gray-300">
                            {{ $t('server.create_port_modal.ip_allocation_method_dynamic_description') }}
                          </p>
                        </div>
                      </div>

                      <div class="relative flex items-start">
                        <div class="flex h-5 items-center">
                          <input id="manual-port" aria-describedby="manual-port-description" type="radio"
                            v-model="form.allocation_method" value="manual"
                            class="h-4 w-4 border-gray-300 text-blue-600 dark:text-blue-600 focus:ring-0 focus:ring-offset-0">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="manual-port-description" class="font-medium text-gray-700 dark:text-gray-300">
                            {{ $t('server.create_port_modal.ip_allocation_method_manual') }}
                          </label>
                          <p id="large-description" class="text-gray-500 dark:text-gray-300">
                            {{ $t('server.create_port_modal.ip_allocation_method_manual_description') }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="sm:col-span-3" v-if="form.allocation_method === 'manual'">
                <div class="flex text-sm flex-col">
                  <label for="ipAddress" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                    {{ $t('server.create_port_modal.ip_address') }}
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="ipAddress"
                      class="appearance-none block w-full dark:bg-gray-700 dark:border-gray-600 px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      :class="{ 'border-red-500': errors.ip_address, 'focus:border-blue-300': !errors.ip_address }"
                      v-model="form.ip_address">
                  </div>
                  <div class="mt-1 text-xs text-red-500" v-if="errors.ip_address">
                    {{ errors.ip_address[0] }}
                  </div>
                  <div v-else class="mt-1 text-xs text-gray-600 dark:text-gray-400">
                    {{ $t('server.create_port_modal.ip_address_description') }}
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="mt-6">
              <div class="rounded-md bg-yellow-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: mini/exclamation-triangle -->
                    <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">
                      {{ $t('server.create_port_modal.missing_private_networks_title') }}
                    </h3>
                    <div class="mt-2 text-sm text-yellow-700">
                      {{ $t('server.create_port_modal.missing_private_networks_description') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span v-if="availablePools.length" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button-spinner :disabled="isLoading" :btnDisable="!form.private_network" @click="submit"
              class="disabled:opacity-75 focus:outline-none focus:ring-0 focus:ring-offset-0 bg-blue-600 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
              {{ $t('server.create_port_modal.create_btn') }}
            </button-spinner>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button @click.prevent="close" type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 dark:border-gray-600 dark:hover:bg-gray-800 px-4 py-2 bg-white dark:bg-gray-700 text-base leading-6 font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              {{ $t('global.cancel') }}
            </button>
          </span>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/modal/Modal'
import ButtonSpinner from '@/components/form/ButtonSpinner'
import CreatePortModalLoader from '@/components/loader/CreatePortModalLoader'

export default {
  name: 'CreatePort',

  components: {
    CreatePortModalLoader,
    Modal,
    ButtonSpinner
  },

  computed: {
    ...mapGetters([
      'server',
      'availablePools',
      'isCreatedPort',
      'createPortErrors'
    ])
  },
  data() {
    return {
      isLoading: false,
      isLoadingPools: false,
      errors: [],
      form: {
        private_network: '',
        allocation_method: 'automatical',
        ip_address: ''
      }
    }
  },

  mounted() {
    this.isLoadingPools = true
    this.$store.dispatch('getAvailablePools', this.server.uuid)
      .finally(() => {
        this.isLoadingPools = false
      })
  },

  methods: {
    close() {
      this.$emit('close')
    },

    submit() {
      this.isLoading = true
      const data = {
        serverId: this.server.uuid,
        form: this.form
      }

      this.$store.dispatch('createPort', data)
    }
  },

  watch: {
    isCreatedPort(status) {
      if (status) {
        this.$toast.success(this.$t('privateNetwork.portCreated'), {
          duration: 3000,
          onClose: () => {
            this.close()
          }
        })
      }
    },

    createPortErrors(formErrors) {
      if (formErrors) {
        const { errors, message } = formErrors
        this.isLoading = false
        this.errors = {
          ...this.errors,
          ...errors
        }

        this.$toast.error(message)
      }
    }
  }
}
</script>

<style>
#create-port .modal-body {
  width: 630px;
}

#create-port .modal-footer {
  padding: 0;
  border-top: none;
}

#create-port select {
  box-shadow: none !important;
}
</style>
