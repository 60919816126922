<template>
  <div class="mt-6">
    <div class="hidden md:block">
      <div class="border-b border-gray-200 dark:border-gray-600">
        <nav class="flex -mb-px space-x-4">
          <router-link :to="'/service/server/' + $route.params.id + '/overview'" class="group tertiary-nav-item space-x-2"
            active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'overview'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
            <span>
              {{ $t('server.navigation.overview') }}
            </span>
          </router-link>

          <router-link v-if="server.app_data" :to="'/service/server/' + $route.params.id + '/application'"
            class="group tertiary-nav-item space-x-2" active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'application'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clip-rule="evenodd"></path>
            </svg>

            <span>
              {{ $t('server.navigation.application') }}
            </span>
          </router-link>

          <router-link :to="'/service/server/' + $route.params.id + '/disks'" class="group tertiary-nav-item space-x-2"
            active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'disks'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z">
              </path>
            </svg>
            <span>
              {{ $t('server.navigation.disks') }}
            </span>
          </router-link>

          <router-link :to="'/service/server/' + $route.params.id + '/network'" class="group tertiary-nav-item space-x-2"
            active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'network'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                clip-rule="evenodd"></path>
            </svg>
            <span>
              {{ $t('server.navigation.network') }}
            </span>
          </router-link>

          <router-link v-if="server.snapshot_enabled" :to="'/service/server/' + $route.params.id + '/snapshots'"
            class="group tertiary-nav-item space-x-2" active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'snapshots'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
              <path fill-rule="evenodd"
                d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                clip-rule="evenodd"></path>
            </svg>
            <span>
              {{ $t('server.navigation.snapshots') }}
            </span>
          </router-link>

          <router-link v-if="server.backup_enabled" :to="'/service/server/' + $route.params.id + '/backup'"
            class="group tertiary-nav-item space-x-2" active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'backup'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 12v3c0 1.657 3.134 3 7 3s7-1.343 7-3v-3c0 1.657-3.134 3-7 3s-7-1.343-7-3z"></path>
              <path d="M3 7v3c0 1.657 3.134 3 7 3s7-1.343 7-3V7c0 1.657-3.134 3-7 3S3 8.657 3 7z"></path>
              <path d="M17 5c0 1.657-3.134 3-7 3S3 6.657 3 5s3.134-3 7-3 7 1.343 7 3z"></path>
            </svg>

            <span>
              {{ $t('server.navigation.backup') }}
            </span>
          </router-link>

          <router-link :to="'/service/server/' + $route.params.id + '/upgrades'" class="group tertiary-nav-item space-x-2"
            active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'upgrades'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z">
              </path>
            </svg>
            <span>
              {{ $t('server.navigation.upgrades') }}
            </span>
          </router-link>

          <router-link :to="'/service/server/' + $route.params.id + '/vnc'" class="group tertiary-nav-item space-x-2"
            active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'vnc'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
                clip-rule="evenodd"></path>
            </svg>
            <span>
              {{ $t('server.navigation.vnc') }}
            </span>
          </router-link>

          <router-link :to="'/service/server/' + $route.params.id + '/statistics'"
            class="group tertiary-nav-item space-x-2" active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'statistics'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z">
              </path>
            </svg>
            <span>
              {{ $t('server.navigation.statistics') }}
            </span>
          </router-link>

          <router-link :to="'/service/server/' + $route.params.id + '/settings'" class="group tertiary-nav-item space-x-2"
            active-class="tertiary-nav-item-active">
            <svg class="h-5 w-5"
              :class="section === 'settings'
                ? 'text-blue-500 group-focus:text-blue-600 dark:text-blue-300 dark:group-focus:text-blue-400'
                : 'text-gray-400 group-hover:text-gray-500 group-focus:text-gray-600 dark:text-gray-100 dark:group-hover:text-gray-300'"
              fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"></path>
            </svg>
            <span>
              {{ $t('server.navigation.settings') }}
            </span>
          </router-link>
        </nav>
      </div>

      <div class="rounded-md bg-yellow-50 dark:bg-yellow-900 p-4 mt-6 shadow"
        v-if="new Date(server.extended_until) <= new Date() && !server?.suspended">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800 dark:text-yellow-300">
              {{ $t('server.expired') }}
            </h3>
            <div class="mt-2 text-sm text-yellow-700 dark:text-yellow-400">
              <p>
                {{ $t('server.expired_description') }}
              </p>
            </div>
            <div class="mt-4">
              <div class="-mx-2 -my-1.5 flex">
                <button type="button" @click="showExtendServerRuntimeModal = true"
                  class="bg-yellow-50 dark:bg-yellow-700 px-2 py-1.5 my-1 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 dark:text-yellow-300 dark:hover:text-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600">
                  {{ $t('server.extend_now') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <extend-server-runtime-modal :isOpen="showExtendServerRuntimeModal" @close="showExtendServerRuntimeModal = false"
    @extendSuccess="refreshServer" :server="server" />
</template>

<script>
import ExtendServerRuntimeModal from '@/components/service/modals/ExtendServerRuntimeModal'
import { mapGetters } from "vuex";

export default {
  name: 'ShowServerNavbar',

  components: { ExtendServerRuntimeModal },

  props: {
    section: {
      type: String,
      required: true
    },
    server: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['server'])
  },

  data() {
    return {
      showExtendServerRuntimeModal: false
    }
  },

  methods: {
    refreshServer() {
      this.showExtendServerRuntimeModal = true

      this.$emit('refreshServer')

      setTimeout(() => {
        this.showExtendServerRuntimeModal = false
      }, 3000)
    },
  }
};
</script>
