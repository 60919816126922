<template>
  <template v-if="!isEditServerName">
    <div @dblclick="setEditableServerName">{{ server.name }}</div>
    <button class="ml-3 hidden group-hover:block " @click="setEditableServerName" v-if="!isEditServerName && server.name">
      <svg
        class="h-5 w-5 text-gray-700 group-hover:text-gray-700 group-focus:text-gray-700 dark:text-gray-300 dark:group-hover:text-gray-300 dark:group-focus:text-gray-300"
        viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2"
        stroke-linecap="round" stroke-linejoin="round">
        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
      </svg>
    </button>
  </template>
  <div class="sm:flex w-full" v-else v-click-away="cancelEditableServerName">
    <input id="serverName"
      :class="(formErrors.name.length ? 'border-red-500' : '') + 'appearance-none block w-full dark:bg-gray-700 px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-gray-300 placeholder-gray-400 focus:border-blue-300 focus:shadow-outline-blue'"
      v-model="serverName" @keyup.enter="updateServerName">
    <div class="sm:flex">
      <span class="flex w-full rounded-md shadow-sm sm:w-auto">
        <button-spinner :disabled="isSubmit" @click="updateServerName"
          class="disabled:opacity-75 focus:outline-none focus:ring-0 focus:ring-offset-0 bg-blue-600 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
          {{ $t('global.update') }}
        </button-spinner>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue3-click-away'
import ButtonSpinner from '@/components/form/ButtonSpinner'

export default {
  name: 'ServerHeader',

  props: {
    server: {
      type: Object,
      required: true
    }
  },

  mixins: [clickaway],

  components: { ButtonSpinner },

  computed: {
    ...mapGetters(['isServerNameUpdated', 'serverNameErrors'])
  },

  data() {
    return {
      isEditServerName: false,
      isSubmit: false,
      serverName: '',
      formErrors: {
        name: ''
      }
    }
  },

  methods: {
    setEditableServerName() {
      this.isEditServerName = true
      this.serverName = this.server.name
    },

    cancelEditableServerName() {
      this.isEditServerName = false
    },

    updateServerName() {
      this.isSubmit = true

      const data = {
        serverId: this.server.uuid,
        form: {
          name: this.serverName
        }
      }

      this.$store.dispatch('updateServerMeta', data)
    }
  },

  watch: {
    isServerNameUpdated(isUpdated) {
      if (isUpdated) {
        this.isSubmit = false

        this.cancelEditableServerName()
        this.$emit('updated')
      }
    },

    serverNameErrors(formErrors) {
      if (formErrors) {
        this.isSubmit = false
        const { errors, message } = formErrors
        this.formErrors = {
          ...this.errors,
          ...errors
        }

        if (!this.formErrors.codeManyRequest) {
          this.$toast.error(message)
        }
      }
    }
  },

  destroyed() {
    this.$destroy()
  }
}
</script>
