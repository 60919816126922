<template>
  <div @mouseover="showTooltip" @mouseout="hideTooltip">
    <slot></slot>
    <div
      class="text-xs absolute mb-1 px-2 z-10 bottom-full left-1/2 transform -translate-x-1/2 p-1 rounded-lg bg-gray-600 text-white whitespace-nowrap dark:bg-gray-500"
      :class="{ 'opacity-0 invisible': !show, 'opacity-100 visible': show }">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showTooltip() {
      this.show = true;
    },
    hideTooltip() {
      this.show = false;
    }
  }
}
</script>
