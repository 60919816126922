<template>
  <div class="flex rounded-md shadow-sm">
    <form @submit.prevent="onSubmit" class="flex">
      <div class="relative flex-grow focus-within:z-10">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd" />
          </svg>
        </div>
        <input :id="inputId" v-model="inputValue" :placeholder="placeholder" :disabled="disabled"
          class="appearance-none block w-full px-3 py-2 border rounded-none rounded-l-md pl-10 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-gray-300 dark:border-gray-600 placeholder-gray-400 dark:bg-gray-700 disabled:bg-gray-100 disabled:cursor-not-allowed truncate" />
      </div>
      <button :disabled="disabled"
        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm leading-5 font-medium rounded-r-md text-gray-700 dark:text-gray-100 transition ease-in-out duration-150 bg-white dark:bg-gray-700 hover:text-gray-500 hover:bg-white focus:outline-none active:bg-gray-100 active:text-gray-700">
        {{ $t('global.search') }}
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    inputId: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputValue: this.modelValue
    };
  },
  watch: {
    modelValue(newVal) {
      this.inputValue = newVal;
    },
    inputValue(newVal) {
      this.$emit('update:modelValue', newVal);
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.inputValue);
    }
  }
};
</script>
