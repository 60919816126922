<template>
  <button type="submit"
    :class="btnClass + (btnDisable || disabled ? ' cursor-not-allowed' : (hoverColor ? ' ' + hoverColor : ' hover:bg-blue-700'))"
    :disabled="btnDisable ? (disabled ? disabled : btnDisable) : disabled">
    <svg v-show="disabled" class="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24" :class="spinnerColor">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
      </path>
    </svg>
    <div v-if="content" v-html="content"></div>
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: 'ButtonSpinner',

  props: {
    disabled: {
      type: Boolean,
      required: false
    },
    btnDisable: {
      type: Boolean,
      required: false
    },
    hoverColor: {
      type: String,
      required: false
    },
    class: {
      type: String,
      required: false
    },
    content: {
      type: String,
      required: false
    },
    spinnerColor: {
      type: String,
      required: false,
      default: 'text-white'
    }
  },

  data() {
    return {
      btnClass: this.class ? this.class : 'disabled:opacity-75 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out'
    }
  },

  destroyed() {
    this.$destroy()
  }
}
</script>
