<template>
  <nav
    class="bg-white dark:bg-gray-700 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-600 sm:px-6"
    aria-label="Pagination">
    <div class="hidden sm:block">
      <div class="text-sm text-gray-700 dark:text-gray-300 flex items-center space-x-2">
        <span>{{ $t('global.pagination.show') }}</span>
        <div class="font-medium">
          <div class="h-3 w-8 bg-gray-300 rounded-md animate-pulse"></div>
        </div>
        <span>{{ $t('global.pagination.to') }}</span>
        <div class="font-medium">
          <div class="h-3 w-8 bg-gray-300 rounded-md animate-pulse"></div>
        </div>
        <span>{{ $t('global.pagination.of') }}</span>
        <div class="font-medium">
          <div class="h-3 w-8 bg-gray-300 rounded-md animate-pulse"></div>
        </div>
        <span>{{ $t('global.pagination.results') }}</span>
      </div>
    </div>
    <div class="flex-1 flex justify-between sm:justify-end">
      <button disabled
        class="disabled:opacity-75 cursor-not-allowed relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700">
        {{ $t('global.pagination.previous') }}
      </button>
      <button disabled
        class="disabled:opacity-75 cursor-not-allowed ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700">
        {{ $t('global.pagination.next') }}
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'PaginationLoader'
}
</script>
