<template>
  <div class="text-center border-dashed border py-12 border-2 border-gray-300 rounded sm:pb-8">
    <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01">
      </path>
    </svg>
    <h3 class="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
      {{ $t('server.emptyStateTitle') }}
    </h3>
    <p class="mt-1 text-sm text-gray-500 dark:text-gray-100">
      {{ $t('server.emptyStateBody') }}
    </p>
    <div class="mt-6">
      <router-link to="/service/server/create"
        class="hover:bg-blue-700 focus:outline-none focus:ring-0 focus:ring-offset-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600">
        {{ $t('server.emptyStateButtonCTA') }}
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'serverEmptyState'
}
</script>
