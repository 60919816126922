<template>
  <div>
    <template v-for="(host, n) in hostnames">
      <div class="relative rounded-md shadow-sm mt-3">
        <input :id="'hostname-' + n" v-model="hostnames[n]"
          class="dark:bg-gray-700 appearance-none block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-gray-300 dark:border-gray-600 placeholder-gray-400 dark:placeholder-gray-500 focus:border-blue-300 focus:shadow-outline-blue"
          :class="{ 'border-red-500': errors && errors[`hostnames.${n}`] }" @change="emitChange">
      </div>
      <div class="mt-2 text-sm text-red-600 dark:text-red-300" v-if="errors && Object.keys(errors).length && errors[`hostnames.${n}`]">{{
        errors[`hostnames.${n}`][0] }}</div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HostnameSelector',

  props: {
    hostnames: {
      type: Array,
      required: true
    },
  },

  computed: {
    ...mapGetters(['serverCreateErrorMessages']),
  },

  data() {
    return {
      errors: {}
    }
  },

  methods: {
    emitChange() {
      this.$emit('selectHostnames', this.hostnames)
    },
  },

  watch: {
    hostnames: {
      handler() {
        this.emitChange()
      },
      deep: true,
    },

    serverCreateErrorMessages(formErrors) {
      this.errors = formErrors.errors
    }
  }
}
</script>
