<template>
  <tr>
    <td colspan="{{ colspan }}"
      class="px-6 py-4 whitespace-no-wrap border-gray-200 dark:border-gray-600 text-sm text-gray-800 dark:text-gray-200">
      {{ $t('global.no_entries_available') }}
    </td>
  </tr>
</template>
<script>
export default {
  name: 'NoTableEntries',

  props: {
    colspan: {
      type: String,
      required: true
    }
  }
}
</script>
