<template>
  <div>
    <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-500">
      <thead>
        <tr>
          <th
            class="px-6 py-3 bg-white dark:bg-gray-700 text-left text-xs leading-4 font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
            {{ $t('server.server_table.server') }}
          </th>
          <th
            class="px-6 py-3 bg-white dark:bg-gray-700 text-left text-xs leading-4 font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
            {{ $t('server.server_table.location') }}
          </th>
          <th
            class="px-6 py-3 bg-white dark:bg-gray-700 text-left text-xs leading-4 font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
            {{ $t('server.server_table.ip') }}
          </th>
          <th
            class="px-6 py-3 bg-white dark:bg-gray-700 text-left text-xs leading-4 font-medium text-gray-500 dark:text-gray-100 uppercase tracking-wider">
            {{ $t('server.server_table.duration') }}
          </th>
          <th class="px-6 py-3 bg-white dark:bg-gray-700"></th>
        </tr>
      </thead>
      <tbody class="bg-white dark:bg-gray-700 divide-y divide-gray-200 dark:divide-gray-500">
        <tr v-for="i in 3">
          <td class="px-6 py-4 whitespace-no-wrap">
            <div class="flex items-center space-x-3">
              <div class="flex items-center">
                <svg class="h-4 w-4 text-gray-300 animate-pulse" fill="currentColor" viewBox="0 0 8 8">
                  <circle cx="4" cy="4" r="3" />
                </svg>
              </div>
              <div>
                <div class="h-3 w-48 bg-gray-300 rounded-md animate-pulse"></div>
                <div class="h-3 mt-2 w-20 bg-gray-300 rounded-md animate-pulse"></div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap">
            <div class="h-3 w-24 bg-gray-300 rounded-md animate-pulse"></div>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap">
            <div class="h-3 w-36 bg-gray-300 rounded-md animate-pulse"></div>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap">
            <div class="h-3 w-20 bg-gray-300 rounded-md animate-pulse"></div>
            <div class="h-3 w-20 mt-2 bg-gray-300 rounded-md animate-pulse"></div>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
            <div class="h-3 w-20 bg-gray-300 rounded-md animate-pulse"></div>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination-loader />
  </div>
</template>

<script>
import PaginationLoader from '@/components/loader/PaginationLoader'

export default {
  name: 'ServerListLoader',
  components: { PaginationLoader }
}
</script>
