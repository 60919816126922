<template>
  <img :src="assets.logo" alt="Logo">
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'WhiteLogo',
  data() {
    return {
      assets: null
    }
  },
  created() {
    this.assets = inject('assets');
  }
}
</script>
