<template>
  <dropdown placeholder="Select Version" :title="title" ref="dropdown" :selected="selected"
    @dropdown="selectImage(selection)">
    <template v-slot:icon v-if="rapidDeployment">
      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
          d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
          clip-rule="evenodd"></path>
      </svg>
    </template>
    <template v-slot:selection>
      <span v-if="selection.name">{{ selection.os_version }}</span>
      <span v-else>{{ $t('server.select_version') }}</span>
    </template>
    <template v-slot:logo>
      <slot name="logo"></slot>
    </template>
    <dropdown-menu>
      <div class="rounded-md bg-white dark:bg-gray-700 shadow-xs">
        <dropdown-element v-for="(option, index) in image" :key="index" :option="option.os_version" :value="option"
          @selection="selectImage($event)">
          <template v-slot:note="prop">
            <div class="inline-flex items-center space-x-1.5">
              <span v-if="option.deprecated"
                class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800 dark:text-gray-200">{{
                  $t('server.deprecated') }}</span>
              <div v-if="selection.name === option.name">
                <svg class="w-5 h-5 text-blue-500" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
            </div>
          </template>
        </dropdown-element>
      </div>
    </dropdown-menu>
  </dropdown>
</template>

<script>
import Dropdown from '@/components/service/dropdown/Dropdown'
import DropdownMenu from '@/components/service/dropdown/DropdownMenu'
import DropdownElement from '@/components/service/dropdown/DropdownElement'

export default {
  name: 'ImageSelectionCard',

  components: { Dropdown, DropdownMenu, DropdownElement },

  model: {
    prop: 'selection',
    event: 'change',
  },

  props: {
    rapidDeployment: {
      type: Boolean,
      default: false,
      required: false,
    },
    deprecated: {
      type: Boolean,
      default: false,
      required: false,
    },
    image: {
      type: Array,
      required: true
    },
    osType: {
      type: String,
      required: true
    },
    selectedImage: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selection: {}
    }
  },

  computed: {
    title() {
      switch (this.osType) {
        case 'windows-server':
          return "Windows Server"
        case 'centos':
          return "CentOS"
        case 'openvpn':
          return "OpenVPN"
        case 'wireguard':
          return "WireGuard VPN"
        case 'hestiacp':
          return "HestiaCP"
        case 'rocketchat':
          return "Rocket.Chat"
        case '3cx':
          return "3CX"
        case 'k3s':
          return "k3s"
        case 'router-os':
          return "RouterOS"
        case 'nixos':
          return "NixOS"
        case 'oracle-linux':
          return "Oracle Linux"
        case 'nodejs':
          return "NodeJS"
        case 'vyos':
          return "VyOS"
        case 'almalinux':
          return "AlmaLinux"
        case 'rockylinux':
          return "Rocky Linux"
        default:
          return this.osType.charAt(0).toUpperCase() + this.osType.slice(1)
      }
    },

    selected: function () {
      return this.selectedImage.name && this.selectedImage.name === this.selection.name
    },
  },

  mounted() {
    this.image.forEach(item => {
      if (this.selectedImage.name === item.name) {
        this.selection = this.selectedImage
      }
    })
  },

  methods: {
    selectImage: function (image) {
      if (!image.name) {
        image = this.image[0]
      }

      this.$refs.dropdown.sharedState.selected = image
      this.selection = image

      this.$emit('selectImage', image)
    }
  }
}
</script>
