<template>
  <dropdown placeholder="Select Datacenter" :title="$t('countries.location.' + region.name)" ref="dropdown"
    :selected="selected" @dropdown="selectCluster(selection)">
    <template v-slot:selection>
      <span v-if="selection.name">{{ selection.alias ?? selection.name }}</span>
      <span v-else>{{ $t('server.datacenter_selection_card.select_location') }}</span>
    </template>
    <template v-slot:logo>
      <slot name="logo"></slot>
    </template>
    <dropdown-menu>
      <div class="rounded-md bg-white dark:bg-gray-700 shadow-xs">
        <dropdown-element v-for="(option, index) in region.clusters" :key=index :option="option.alias ?? option.name"
          :value="option" @selection="selectCluster($event)">
          <template v-slot:note="prop">
            <div v-if="selection.name === option.name">
              <svg class="w-5 h-5 text-blue-500" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"></path>
              </svg>
            </div>
          </template>
        </dropdown-element>
      </div>
    </dropdown-menu>
  </dropdown>
</template>

<script>
import Dropdown from '@/components/service/dropdown/Dropdown'
import DropdownMenu from '@/components/service/dropdown/DropdownMenu'
import DropdownElement from '@/components/service/dropdown/DropdownElement'

export default {
  name: 'DatacenterSelectionCard',

  components: { Dropdown, DropdownMenu, DropdownElement },

  model: {
    prop: 'selection',
    event: 'change',
  },

  props: {
    region: {
      required: true
    },
    selectedRegion: {}
  },

  data() {
    return {
      selection: {}
    }
  },

  computed: {
    selected() {
      return this.selectedRegion.name && this.selectedRegion.name === this.selection.name
    },
  },

  methods: {
    selectCluster: function (cluster) {
      if (!cluster.name) {
        cluster = this.region.clusters[0]
      }

      this.$refs.dropdown.sharedState.selected = cluster
      this.selection = cluster

      this.$emit('selectRegion', cluster)
    }
  }
}
</script>
