<template>
  <Tooltip :text="$t('server.auto_extend')">
    <ArrowPathIcon class="cursor-help h-3.5 w-aut text-gray-500 dark:text-gray-300" />
  </Tooltip>
</template>

<script>
import { ArrowPathIcon } from '@heroicons/vue/24/outline'
import Tooltip from '@/components/common/Tooltip'

export default {
  components: {
    ArrowPathIcon,
    Tooltip
  },
}
</script>
