<template>
  <nav class="bg-gray-800">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <div class="w-32">
              <router-link to="/">
                <WhiteLogo />
              </router-link>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-10 flex items-baseline space-x-3">
              <router-link to="/" active-class="customer-top-nav-item-active" class="customer-top-nav-item">
                {{ $t('commonNavigation.primaryNavigation.dashboard') }}
              </router-link>
              <router-link to="/service/server"
                :class="($route.path.indexOf('service') > -1 ? 'customer-top-nav-item-active' : '') + ' customer-top-nav-item'">
                {{ $t('commonNavigation.primaryNavigation.service') }}
              </router-link>
              <router-link to="/payment/balance"
                :class="($route.path.indexOf('payment') > -1 ? 'customer-top-nav-item-active' : '') + ' customer-top-nav-item'">
                {{ $t('commonNavigation.primaryNavigation.payment') }}
              </router-link>
              <router-link to="/support/ticket"
                :class="($route.path.indexOf('support') > -1 ? 'customer-top-nav-item-active' : '') + ' customer-top-nav-item'">
                {{ $t('commonNavigation.primaryNavigation.support') }}
              </router-link>
              <a href="https://docs.vps2day.com/virtual-servers/" target="_blank" class="customer-top-nav-item">
                {{ $t('commonNavigation.primaryNavigation.docs') }}
              </a>
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6 space-x-4">
            <router-link to="/payment/balance"
              class="px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-200 bg-gray-700">
              <div v-if="balance.formatted === 0" class="h-5 w-14 bg-gray-700 rounded-md animate-pulse"></div>
              <span v-else>{{ balance ? balance.formatted : '0,00' }} &euro; </span>
            </router-link>

            <div class="relative">
              <router-link aria-label="Notifications"
                class="block p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
                to="/announcement">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                </svg>
              </router-link>

              <span v-if="hasAnnouncements" class="flex absolute h-3 w-3 top-0 right-0 -mt-0.5 -mr-0.5">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
              </span>
            </div>

            <div class="relative cursor-pointer">
              <div @click="toggleDarkMode"
                class="mr-2 p-1 text-gray-400 hover:text-white focus:outline-none focus:text-white focus:bg-gray-700">
                <svg v-if="isDark.value" fill="none" class="h-6 w-6" stroke-width="2" stroke="currentColor"
                  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z">
                  </path>
                </svg>
                <svg v-else fill="none" class="h-6 w-6" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z">
                  </path>
                </svg>
              </div>
            </div>

            <div class="relative">
              <div>
                <button id="user-menu" aria-haspopup="true" aria-label="User menu"
                  class="focus:z-10 max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                  @click="toggleDropdownMenu">
                  <span class="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
                    <svg class="h-full w-full text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </button>
              </div>

              <div v-if="showDropdown" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50">
                <div aria-labelledby="options-menu" aria-orientation="vertical"
                  class="rounded-md bg-white dark:bg-gray-700 shadow-xs z-50" role="menu" v-click-away="onClickAway">
                  <div class="px-4 py-3">
                    <p class="text-sm leading-5">
                      {{ $t('commonNavigation.customerDropdown.signedInAs') }}
                    </p>
                    <p class="text-sm leading-5 font-medium text-gray-900 dark:text-gray-100 truncate">
                      {{ customer.name }}
                    </p>
                  </div>
                  <div class="border-t border-gray-100 dark:border-gray-600"></div>
                  <div class="py-1">
                    <router-link to="/profile/edit/data"
                      class="block px-4 py-2 text-sm leading-5 text-gray-700 dark:text-gray-300 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-900 dark:text-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900 dark:text-gray-100">
                      {{ $t('commonNavigation.customerDropdown.yourAccount') }}
                    </router-link>
                    <router-link to="/support/ticket"
                      class="block px-4 py-2 text-sm leading-5 text-gray-700 dark:text-gray-300 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-900 dark:text-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900 dark:text-gray-100"
                      role="menuitem">
                      {{ $t('commonNavigation.customerDropdown.support') }}
                    </router-link>
                  </div>
                  <div class="border-t border-gray-100 dark:border-gray-600"></div>
                  <div class="py-1">
                    <a class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900"
                      href="#" @click="changeLocale">
                      <div class="flex items-center">
                        <img :alt="$t('countries.' + ($i18n.locale === 'en' ? 'de' : 'gb'))" class="h-3 w-4 mr-2"
                          src="@/assets/img/flags/gb.svg" v-if="$i18n.locale === 'de'" />
                        <img :alt="$t('countries.' + ($i18n.locale === 'en' ? 'de' : 'gb'))" class="h-3 w-4 mr-2"
                          src="@/assets/img/flags/de.svg" v-if="$i18n.locale === 'en'" />
                        {{ $t('countries.' + ($i18n.locale === 'en' ? 'de' : 'gb')) }}
                      </div>
                    </a>
                  </div>
                  <div class="border-t border-gray-100 dark:border-gray-600"></div>
                  <div class="py-1">
                    <a class="font-normal block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 hover:text-gray-900 dark:text-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900 dark:text-gray-100"
                      href="#" role="menuitem" @click.prevent="handleLogOut">
                      {{ $t('commonNavigation.customerDropdown.signOut') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
            @click="toggleMenu">
            <!-- Menu open: "hidden", Menu closed: "block" -->
            <svg :class="{ 'block': !displayMenu, 'hidden': displayMenu }" class="h-6 w-6" fill="none"
              stroke="currentColor" viewBox="0 0 24 24">
              <path d="M4 6h16M4 12h16M4 18h16" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </svg>
            <!-- Menu open: "block", Menu closed: "hidden" -->
            <svg :class="{ 'block': displayMenu, 'hidden': !displayMenu }" class="h-6 w-6" fill="none"
              stroke="currentColor" viewBox="0 0 24 24">
              <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!--
      Mobile menu, toggle classes based on menu state.

      Open: "block", closed: "hidden"
    -->
    <div :class="{ 'block': displayMenu, 'hidden': !displayMenu }">
      <div class="px-2 pt-2 pb-3 sm:px-3">
        <router-link to="/" active-class="customer-top-nav-item-active"
          class="customer-top-nav-item block text-base px-3 py-2 font-medium">
          {{ $t('commonNavigation.primaryNavigation.dashboard') }}
        </router-link>
        <router-link to="/service/server"
          :class="($route.path.indexOf('service') > -1 ? 'customer-top-nav-item-active' : '') + ' customer-top-nav-item'"
          class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
          {{ $t('commonNavigation.primaryNavigation.service') }}
        </router-link>
        <router-link to="/payment/balance"
          :class="($route.path.indexOf('payment') > -1 ? 'customer-top-nav-item-active' : '') + ' customer-top-nav-item'"
          class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
          {{ $t('commonNavigation.primaryNavigation.payment') }}
        </router-link>
        <router-link to="/support/ticket"
          :class="($route.path.indexOf('support') > -1 ? 'customer-top-nav-item-active' : '') + ' customer-top-nav-item'"
          class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
          {{ $t('commonNavigation.primaryNavigation.support') }}
        </router-link>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <span class="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
              <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path
                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-none text-white">
              {{ customer.first_name }} {{ customer.last_name }}
            </div>
            <div class="mt-1 text-sm font-medium leading-none text-gray-400">
              {{ $t('commonNavigation.customerDropdown.currentBalance') }}: {{ balance ? balance.formatted : '0,00' }}
              &euro;
            </div>
          </div>
        </div>
        <div aria-labelledby="user-menu" aria-orientation="vertical" class="mt-3 px-2" role="menu">
          <router-link to="/profile/edit/data"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
            {{ $t('commonNavigation.customerDropdown.yourAccount') }}
          </router-link>
          <a class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            href="#" role="menuitem" @click.prevent="handleLogOut">
            {{ $t('commonNavigation.customerDropdown.signOut') }}
          </a>
        </div>
      </div>
    </div>
    <transition>
      <complete-profile-banner v-if="!isCustomerRegistrationCompleted" />
    </transition>
    <transition v-if="!$route.fullPath.includes('announcement')">
      <announcement-banner />
    </transition>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WhiteLogo from '@/components/logos/WhiteLogo'
import { mixin as clickaway } from 'vue3-click-away'
import CompleteProfileBanner from '@/components/banner/CompleteProfileBanner'
import AnnouncementBanner from '@/components/banner/AnnouncementBanner'

export default {
  name: 'Navigation',

  mixins: [clickaway],
  inject: ['isDark'],

  props: {
    hasAnnouncements: {
      type: Boolean,
      default: false
    },
    customer: {
      type: Object,
      required: true
    },
    balance: {
      type: Object,
      required: true
    }
  },

  components: {
    AnnouncementBanner,
    WhiteLogo,
    CompleteProfileBanner
  },

  data() {
    return {
      showDropdown: false,
      displayMenu: false,
      displayProfileDropdown: false,
    }
  },

  computed: {
    ...mapGetters(['customer', 'isCustomerRegistrationCompleted']),
  },

  methods: {
    ...mapActions({
      handleUserLogOut: 'userLogOut'
    }),

    toggleDarkMode() {
      this.isDark.value = !this.isDark.value
      localStorage.setItem('darkMode', this.isDark.value)
    },

    onClickAway() {
      this.showDropdown = false;
    },

    handleLogOut() {
      this.handleUserLogOut()
    },

    toggleMenu() {
      this.displayMenu = !this.displayMenu
    },

    toggleDropdownMenu() {
      this.showDropdown = !this.showDropdown
    },

    changeLocale() {
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'de' : 'en'
      this.$store.dispatch('setCustomerLocale', this.$i18n.locale)
    }
  }
}
</script>
