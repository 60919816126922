<template>
  <transition>
    <modal id="upgrade-flavor">
      <template v-slot:body>
        <div class="bg-white dark:bg-gray-700 break-words">
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-blue-600" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M4.293 15.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414 0zm0-6a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L10 5.414 5.707 9.707a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100" id="modal-headline">
                {{ $t('server.upgrade_server_modal.title') }}
              </h3>
              <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500 dark:text-gray-100 prose">
                  {{ $t('server.upgrade_server_modal.description') }}
                </p>
              </div>
              <div class="mt-4" v-if="flavors.length">
                <p class="text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                  {{ $t('server.upgrade_server_modal.upgrade_points') }}
                </p>
                <ul class="text-sm text-gray-500 dark:text-gray-100 list-disc list-inside mt-1">
                  <li>{{ $t('server.upgrade_server_modal.upgrade_point_first') }}</li>
                  <li>{{ $t('server.upgrade_server_modal.upgrade_point_second') }}</li>
                  <li>{{ $t('server.upgrade_server_modal.upgrade_point_third') }}</li>
                </ul>
              </div>
              <div
                class="rounded-md border border-yellow-300 bg-yellow-50 dark:border-yellow-600 dark:bg-yellow-900 p-4 mt-4"
                v-if="!flavors.length">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-yellow-400 dark:text-yellow-300" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800 dark:text-yellow-200">
                      {{ $t('server.upgrade_server_modal.no_flavor_title') }}
                    </h3>
                    <div class="mt-2 text-sm text-yellow-700 dark:text-yellow-300">
                      {{ $t('server.upgrade_server_modal.no_flavor_text') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <fieldset>
                  <legend id="radiogroup-label" class="sr-only">
                    {{ $t('server.upgrade_server_modal.server_flavor') }}
                  </legend>
                  <ul class="space-y-4" role="radiogroup" aria-labelledby="radiogroup-label">
                    <flavor-selection-card @selectFlavor="setFlavor" v-for="(flavor, index) in flavors" :key="index"
                      :index="index" :flavor="flavor" :selectedFlavor="selectedFlavor" />
                  </ul>
                </fieldset>
              </div>

              <div class="grid grid-cols-6 mt-6" v-if="flavors.length">
                <div class="col-span-3">
                  <label for="country" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                    {{ $t('global.immediate_payment') }}
                  </label>
                  <div class="mt-1 text-xl font-semibold text-gray-700 dark:text-gray-300">
                    {{ selectedFlavor.immediate_payment ? '&euro; ' + priceConvertor(selectedFlavor.immediate_payment) :
                      '-' }}
                  </div>
                  <div class="mt-1 text-xs text-gray-500 dark:text-gray-100">
                    {{ $t('global.until_your_next') }} {{ $filters.dateTime(server.extended_until, 'DD. MMM Y') }}
                  </div>
                </div>

                <div class="col-span-3">
                  <label for="country" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                    {{ $t('server.upgrade_server_modal.server_price') }}
                  </label>
                  <div class="mt-1 text-xl font-semibold text-gray-700 dark:text-gray-300">
                    <template v-if="selectedFlavor.monthly_payment_increase">
                      + {{ '&euro; ' + priceConvertor(selectedFlavor.monthly_payment_increase) }} / {{ $t('global.month')
                      }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div class="mt-1 text-xs text-gray-500 dark:text-gray-100">
                    {{ $t('server.upgrade_server_modal.monthly_server_price') }}
                  </div>
                </div>

                <div class="col-span-6 mt-8" v-if="selectedFlavor.immediate_payment && !hasSufficientBalance">
                  <div class="text-xs text-gray-500 dark:text-gray-100" v-html="$t('server.upgrade_server_modal.upgrade_server_terms', {
                    immediatePayment: priceConvertor(selectedFlavor.immediate_payment) ?? '-',
                    monthlyPaymentIncrease: priceConvertor(selectedFlavor.monthly_payment_increase),
                    monthlyGrossPrice: priceConvertor(selectedFlavor.monthly_gross_price),
                  })">
                  </div>
                </div>

                <div class="mt-2 col-span-6 border border-red-200 p-4 bg-red-50 rounded" v-if="hasSufficientBalance">
                  <div class="text-sm text-red-500">
                    <div class="flex items-center space-x-1">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <p class="font-medium">{{ $t('server.upgrade_server_modal.insufficient_balance') }}</p>
                    </div>
                    <div class="mt-1" v-html="$t('server.upgrade_server_modal.immediate_payment',
                      { immediatePayment: '&euro; ' + priceConvertor(selectedFlavor.immediate_payment) })">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="bg-white dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button v-if="!selectedFlavor.name" type="button" :disabled="true"
              class="disabled:opacity-75 w-full justify-center cursor-not-allowed inline-flex items-center px-4 py-3 md:py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 focus:outline-none focus:ring-0 focus:ring-offset-0">
              {{ $t('server.upgrade_server_modal.select_flavor') }}
            </button>

            <button-spinner v-else :disabled="isLoading" :btnDisable="hasSufficientBalance" @click="submit"
              class="disabled:opacity-75 focus:outline-none focus:ring-0 focus:ring-offset-0 bg-blue-600 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-3 md:py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
              {{ $t('server.upgrade_server_modal.pay_btn') }} {{ '&euro; ' +
                priceConvertor(selectedFlavor.immediate_payment) }}
            </button-spinner>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button @click.prevent="close" type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 dark:border-gray-600 dark:hover:bg-gray-800 px-4 py-2 bg-white dark:bg-gray-700 text-base leading-6 font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              {{ $t('global.cancel') }}
            </button>
          </span>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/modal/Modal'
import ButtonSpinner from '@/components/form/ButtonSpinner'
import FlavorSelectionCard from '@/components/service/selectors/FlavorSelectionCard'
import Dinero from 'dinero.js'

export default {
  name: 'UpgradeFlavorModal',

  components: {
    Modal,
    ButtonSpinner,
    FlavorSelectionCard
  },

  props: {
    server: {
      required: true,
      type: Object
    }
  },

  computed: {
    ...mapGetters(['flavors', 'getBalance', 'isUpgrade']),

    hasSufficientBalance() {
      const userBalance = this.getBalance.cents ? this.getBalance.cents : 0

      return userBalance < this.price
    }
  },

  data() {
    return {
      price: 0,
      selectedFlavor: {},
      isLoading: false,
    }
  },

  mounted() {
    this.$store.dispatch('getServerFlavorsCalculation', this.$route.params.id)
  },

  methods: {
    setFlavor(flavor) {
      this.selectedFlavor = flavor
    },

    close() {
      this.$emit('close')
    },

    updatePrice() {
      this.price = this.selectedFlavor.immediate_payment
    },

    priceConvertor(price) {
      return Dinero({ amount: price, currency: 'EUR' })
        .setLocale('de-DE')
        .toFormat('0,0.00')
    },

    submit() {
      this.isLoading = true
      const data = {
        serverId: this.server.uuid,
        form: {
          flavor: this.selectedFlavor.name
        }
      }

      this.$store.dispatch('setServerUpgrade', data)
    }
  },

  watch: {
    selectedFlavor() {
      this.updatePrice()
    },
    isUpgrade(status) {
      this.isLoading = false

      if (status && !status.errors) {
        this.$emit('success')
      } else if (status) {
        this.$toast.error(status.message)
      }
    },
  }
}
</script>

<style>
@media (min-width: 640px) {
  /* For screens larger than 640px */
  #upgrade-flavor .modal-body {
    width: 630px;
  }

  #upgrade-flavor .modal-footer {
    padding: 0;
    border-top: none;
  }
}
</style>
