<template>
  <div>
    <label for="image" class="block text-sm font-medium leading-6 dark:text-gray-200 text-gray-800">
      <span>{{ $t('server.select_version') }}</span>
    </label>
    <select
      id="image"
      ref="imageSelect"
      :value="selection.alias"
      @change="selectImage($event.target.value)"
      class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
    >
      <optgroup v-for="(groupedImages, osType) in images" :label="groupName(osType)" :key="osType">
        <option v-for="image in groupedImages" :value="image.id" :key="image.id">
          {{ image.alias }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
export default {
  name: 'ImageSelectionCardMobile',

  model: {
    prop: 'selection',
    event: 'change',
  },

  props: {
    rapidDeployment: {
      type: Boolean,
      default: false,
      required: false,
    },
    deprecated: {
      type: Boolean,
      default: false,
      required: false,
    },
    images: {
      type: Object,
      required: true,
    },
    selectedImage: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selection: {},
    };
  },

  mounted() {
    this.setInitialSelectedImage();
  },

  watch: {
    category() {
      this.setInitialSelectedImage();
    },
  },

  methods: {
    groupName(name) {
      switch (name) {
        case 'windows-server':
          return 'Windows Server';
        case 'centos':
          return 'CentOS';
        case 'openvpn':
          return 'OpenVPN';
        case 'wireguard':
          return 'WireGuard VPN';
        case 'hestiacp':
          return 'HestiaCP';
        case 'rocketchat':
          return 'Rocket.Chat';
        case '3cx':
          return '3CX';
        case 'k3s':
          return 'k3s';
        case 'router-os':
          return 'RouterOS';
        case 'nixos':
          return 'NixOS';
        case 'oracle-linux':
          return 'Oracle Linux';
        case 'nodejs':
          return 'NodeJS';
        case 'vyos':
          return 'VyOS';
        case 'almalinux':
          return 'AlmaLinux';
        case 'rockylinux':
          return 'Rocky Linux';
        default:
          return name.charAt(0).toUpperCase() + name.slice(1);
      }
    },

    findImage(predicate) {
      for (const osType in this.images) {
        if (this.images.hasOwnProperty(osType)) {
          const image = this.images[osType].find(predicate);
          if (image) {
            return image;
          }
        }
      }
      return null;
    },

    selectImage(alias) {
      const image = this.findImage(image => image.alias === alias);
      if (image) {
        this.selection = image;
        this.$emit('selectImage', image);
      } else {
        console.error('No image found with alias:', alias);
      }
    },

    setInitialSelectedImage() {
      const image = this.findImage(() => true);
      if (image) {
        this.selection = image;
        this.$emit('selectImage', this.selection);
      }
    },
  },
};
</script>
