<template>
  <transition>
    <modal id="create-additional-disk">
      <template v-slot:body>
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-blue-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z">
              </path>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100" id="modal-headline">
              {{ $t('disk.create_modal.create_additional_disk') }}
            </h3>
            <div class="mt-2 text-sm text-gray-600 dark:text-gray-200">
              {{ $t('disk.create_modal.create_additional_disk_text') }}
            </div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="col-span-6 grid grid-cols-6 gap-x-4">
                <div class="col-span-6 sm:col-span-4">
                  <label for="name" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                    {{ $t('disk.create_modal.name') }}
                  </label>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input id="name" v-model="diskName" type="text"
                      :class="{ 'border-red-500 focus:ring-red-500': errors.name, 'focus:outline-none focus:border-blue-500 focus:ring-blue-500': !errors.name }"
                      class="dark:bg-gray-700 dark:border-gray-400 shadow-sm block w-full sm:text-sm border-gray-300 rounded-md">
                  </div>
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="size" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                    {{ $t('disk.create_modal.size') }}
                  </label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="size" type="text"
                      class="dark:bg-gray-700 dark:border-gray-400 shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="min. 1"
                      :class="{ 'border-red-500 focus:ring-red-500': errors.size, 'focus:outline-none focus:border-blue-500 focus:ring-blue-500': !errors.size }"
                      aria-describedby="size-unit" v-model="size" @keypress="isNumber($event)">
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span class="text-gray-500 dark:text-gray-400 sm:text-sm sm:leading-5" id="size-unit">
                        GB
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-span-6">
                  <div class="mt-2 text-sm text-gray-500 dark:text-gray-100">
                    {{ $t('disk.create_modal.name_label') }}
                  </div>
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                  {{ $t('disk.create_modal.auto_format') }}
                </label>
                <div class="mt-1 rounded-md shadow-sm">
                  <select id="autoFormat" v-model="autoFormat"
                    class="dark:bg-gray-700 dark:border-gray-400 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                    <option v-if="server.image.os_family === 'linux'" value="ext4">ext4</option>
                    <option v-if="server.image.os_family === 'linux'" value="xfs">xfs</option>
                    <option value="manual">{{ $t('disk.create_modal.format_manual') }}</option>
                  </select>
                </div>
              </div>

              <div class="col-span-6">
                <template v-if="autoFormat !== 'manual'">
                  <div class="text-sm text-gray-500 dark:text-gray-100"
                    v-html="$t('disk.create_modal.auto_format_choosing')"></div>
                  <div class="text-sm text-gray-500 dark:text-gray-100 mt-2">
                    {{ $t('disk.create_modal.auto_format_choosing_not_restart') }}
                  </div>
                </template>
                <template v-else>
                  <div class="text-sm text-gray-500 dark:text-gray-100"
                    v-html="$t('disk.create_modal.manual_format_choosing')"></div>
                  <div class="text-sm text-red-500 mt-2">
                    <div class="flex items-center space-x-1">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <p class="font-medium">
                        {{ $t('disk.create_modal.warning') }}
                      </p>
                    </div>
                    <div class="mt-1">{{ $t('disk.create_modal.manual_format_only') }}</div>
                  </div>
                </template>
              </div>

              <div class="col-span-3">
                <label for="country" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                  {{ $t('disk.create_modal.immediate_payment') }}
                </label>
                <div class="mt-1 text-xl font-semibold text-gray-700 dark:text-gray-300">
                  <template v-if="immediate && !isPriceLoading">
                    {{ immediate }} &euro;
                  </template>
                  <template v-else-if="isPriceLoading">
                    <div class="h-6 mt-2 w-20 bg-gray-300 rounded-md animate-pulse"></div>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="mt-1 text-xs text-gray-500 dark:text-gray-100">
                  {{ $t('disk.create_modal.next_payment') }} {{ $filters.dateTime(server.extended_until, 'YYYY-MM-DD') }}
                </div>
              </div>

              <div class="col-span-3">
                <label for="country" class="block text-sm font-medium leading-5 text-gray-700 dark:text-gray-300">
                  {{ $t('disk.create_modal.server_price') }}
                </label>
                <div class="mt-1 text-xl font-semibold text-gray-700 dark:text-gray-300">
                  <template v-if="monthlyDiskPrice && !isPriceLoading">
                    + {{ monthlyDiskPrice }} &euro; / {{ $t('disk.create_modal.month') }}
                  </template>
                  <template v-else-if="isPriceLoading">
                    <div class="h-6 mt-2 w-40 bg-gray-300 rounded-md animate-pulse"></div>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
                <div class="mt-1 text-xs text-gray-500 dark:text-gray-100">
                  {{ $t('disk.create_modal.server_price_monthly') }}
                </div>
              </div>

              <div class="col-span-6">
                <div class="text-xs text-gray-500 dark:text-gray-100" v-if="newMonthlyServerPrice"
                  v-html="$t('disk.create_modal.by_clicking', { immediate: immediate + ' &euro;' ?? '-', monthlyDiskPrice: monthlyDiskPrice, newMonthlyServerPrice: newMonthlyServerPrice })">
                </div>
              </div>

              <div class="col-span-6 border border-red-200 p-4 bg-red-50 rounded" v-if="isEnoughBalance">
                <div class="text-sm text-red-500">
                  <div class="flex items-center space-x-1">
                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd"></path>
                    </svg>
                    <p class="font-medium">{{ $t('disk.create_modal.insufficient_balance') }}</p>
                  </div>
                  <div class="mt-1" v-html="$t('disk.create_modal.no_money', { immediate: immediate })">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:w-auto">
            <button-spinner :disabled="isLoading" :btnDisable="isEnoughBalance" @click="submit"
              class="disabled:opacity-75 focus:outline-none focus:ring-0 focus:ring-offset-0 bg-blue-600 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
              {{ $t('disk.create_modal.create') }}
            </button-spinner>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button @click.prevent="close" type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white dark:bg-gray-700 dark:hover:bg-gray-800 dark:border-gray-600 text-base leading-6 font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              {{ $t('disk.create_modal.cancel') }}
            </button>
          </span>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/modal/Modal'
import ButtonSpinner from '@/components/form/ButtonSpinner'
import { debounce } from '@/helpers/debounce'
import Dinero from 'dinero.js'

export default {
  name: 'CreateAdditionalDiskModal',

  props: {
    server: {
      required: true,
      type: Object
    }
  },

  components: {
    Modal,
    ButtonSpinner
  },

  computed: {
    ...mapGetters([
      'diskPricing',
      'diskPricingError',
      'isDiskCreated',
      'diskCreateErrors'
    ]),
    ...mapGetters({ balance: 'getBalance' }),
    isEnoughBalance() {
      return this.balance.cents < this.diskPricing.immediate_payment
    }
  },

  data() {
    return {
      isPriceLoading: false,
      isLoading: false,
      insufficientBalance: false,
      monthlyDiskPrice: false,
      immediate: 0,
      newMonthlyServerPrice: 0,
      size: '',
      diskName: '',
      autoFormat: 'ext4',
      errors: {}
    }
  },

  mounted() {
    if (this.server.image.os_family !== 'linux') {
      this.autoFormat = 'manual'
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    priceString(price) {
      price = parseFloat(price)
      price = price >= 0 ? price : 0

      return Dinero({ amount: price, currency: 'EUR' })
        .setLocale('de-DE')
        .toFormat('0,0.00')
    },

    submit() {
      this.isLoading = true
      this.errors = {}
      const data = {
        serverId: this.$route.params.id,
        form: {
          name: this.diskName,
          size: this.size,
          auto_format: this.autoFormat
        }
      }

      this.$store.dispatch('createAdditionalDisk', data).finally(() => {
        this.isLoading = false
      })
    },

    isNumber: function (event) {
      event = (event) ? event : window.event

      const charCode = (event.which) ? event.which : event.keyCode

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        event.preventDefault()
      } else {
        return true
      }
    },

    calculateSize() {
      this.isPriceLoading = true
      const data = {
        serverId: this.$route.params.id,
        form: {
          size: this.size,
          auto_format: this.autoFormat
        }
      }

      this.$store.dispatch('calculateDiskPrice', data).then(() => {
        this.isPriceLoading = false
      })
    }
  },

  watch: {
    size: debounce(function (size) {
      this.size = size

      this.calculateSize()
    }, 750),

    diskPricing(pricing) {
      if (pricing) {
        this.errors = {}
        this.monthlyDiskPrice = this.priceString(pricing.monthly_payment)
        this.immediate = this.priceString(pricing.immediate_payment)
        this.newMonthlyServerPrice = this.priceString(pricing.monthly_payment + this.server.flavor.monthly_gross_price)
      }
    },

    diskPricingError(formErrors) {
      if (formErrors.errors) {
        const { errors, message } = formErrors
        this.errors = errors

        this.$toast.error(message)
      }
    },

    isDiskCreated(status) {
      if (status) {
        this.$emit('success')
      }
    },

    diskCreateErrors(formErrors) {
      if (formErrors.errors) {
        const { errors, message } = formErrors
        this.isLoading = false
        this.errors = errors

        this.$toast.error(message)
      } else if (formErrors.message) {
        this.isLoading = false
      }
    }
  }
}
</script>

<style>
#create-additional-disk .modal-body {
  width: 630px;
}

#create-additional-disk .modal-footer {
  padding: 0;
  border-top: none;
}
</style>
