<template>
  <div class="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50">
    <div class="modal" v-click-away="close" :class="style ? style : ''">
      <header class="modal-header hidden relative border-b border-gray-200 dark:border-gray-400 p-4 flex justify-between">
        <slot name="header" />
        <button type="button" class="btn-close" @click="close">
          x
        </button>
      </header>

      <section class="modal-body w-[465px] relative p-5">
        <slot name="body" />
      </section>

      <footer class="modal-footer border-t border-gray-200 dark:border-gray-400 p-4 flex flex-col items-end">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue3-click-away'

export default {
  name: 'Modal',

  mixins: [clickaway],

  props: ['style'],

  methods: {
    close() {
      this.$emit('close');
    }
  },

  destroyed() {
    this.$destroy()
  }
};
</script>

<style scoped>
.modal {
  @apply bg-white dark:bg-gray-700 rounded-lg shadow-lg overflow-x-auto flex flex-col;
}

.btn-close {
  @apply absolute top-0 right-0 border-none text-lg p-2 cursor-pointer text-black bg-transparent;
}

@media (max-width: 640px) {
  .modal-body {
    width: 100%;
    max-width: 630px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .modal-footer {
    padding: 0;
    border-top: none;
    overflow-y: auto;
    display: flex;
    flex-direction: row-reverse;
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch;
  }

  .modal-footer > * {
    margin-right: 10px; /* Add space between buttons */
  }

  .modal-footer > *:last-child {
    margin-bottom: 0; /* Remove margin from the last button */
  }

  .modal {
    margin-right: 20px;
    margin-left: 20px;
  }
}
</style>
