<template>
  <div class="relative bg-blue-600">
    <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:px-16 sm:text-center">
        <p class="font-medium text-white">
          <span class="md:hidden">{{ $t('commonNavigation.completeProfileBanner.textMobile') }}</span>
          <span class="hidden md:inline">
            {{ $t('commonNavigation.completeProfileBanner.textDesktop') }}
          </span>
          <span class="block sm:ml-2 sm:inline-block">
            <router-link to="/profile/edit/data" class="font-bold text-white underline">
              {{ $t('commonNavigation.completeProfileBanner.cta') }}
              <span aria-hidden="true"> &rarr;</span>
            </router-link>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompleteProfileBanner"
}
</script>
