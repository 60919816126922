<template>
  <div class="flow-root">
    <div class="flex items-start space-x-3 my-5">
      <div class="min-w-0 flex-1">
        <div class="space-y-3">
          <div class="h-3 w-32 rounded-md bg-gray-300 animate-pulse" />
          <div class="h-8 w-68 rounded-md bg-gray-300 animate-pulse" />
        </div>
        <div class="mt-4 space-y-3">
          <div class="h-3 w-36 rounded-md bg-gray-300 animate-pulse" />
          <div class="mb-4 space-y-3">
            <div class="h-4 w-32 rounded-md bg-gray-300 animate-pulse" />
            <div class="h-3 w-64 rounded-md bg-gray-300 animate-pulse" />
          </div>
          <div class="space-y-3">
            <div class="h-4 w-32 rounded-md bg-gray-300 animate-pulse" />
            <div class="h-3 w-64 rounded-md bg-gray-300 animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreatePortModalLoader"
}
</script>

<style scoped></style>
