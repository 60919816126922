<template>
  <Tooltip :text="hasCopied ? $t('commonGlobal.copy') : $t('commonGlobal.copy')">
    <DocumentDuplicateIcon v-if="!hasCopied" @click="copy" class="cursor-pointer w-auto text-gray-500 dark:text-gray-100"
      :class="height" />
    <svg v-else :class="height" class="w-auto text-blue-600 dark:text-blue-300" fill="currentColor" viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clip-rule="evenodd"></path>
    </svg>
  </Tooltip>
</template>

<script>
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline'
import Tooltip from '@/components/common/Tooltip'

export default {
  components: {
    DocumentDuplicateIcon,
    Tooltip
  },
  props: {
    dataToCopy: {
      type: [String, Number, Object],
      required: true
    },
    height: {
      type: String,
      default: "h-4",
      required: false
    }
  },
  data() {
    return {
      hasCopied: false,
    }
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.dataToCopy)
      this.hasCopied = true
      setTimeout(() => {
        this.hasCopied = false
      }, 1600)
    },
  },
}
</script>
