import { ServerPortTypeEnum } from '@/enums'


/**
 * Retrieves the specified field value from the main IP allocation of the server.
 *
 * @param {object} server - The server object.
 * @param {string} type - The IP family type.
 * @param {string} field - The field to retrieve from the IP allocation.
 * @return {any} The value of the specified field from the main IP allocation, or `false` if not found.
 */
export function getServerMainIpField(server, type, field) {
  let mainIp = server.ports.map((port) => {
    if (port.type === ServerPortTypeEnum.TYPE_PUB_PRIMARY) {
      return port.ip_allocations.map((ip) => {
        if (ip.ip_family === type) {
          return ip[field]
        }
      })
    }
  })

  if (mainIp.length && Array.isArray(mainIp[0])) {
    mainIp = mainIp[0].filter((item) => {
      return item !== undefined
    })
  }

  return mainIp.length ? mainIp[0] : false
}
