<script>
import moment from "moment/moment";

export default {
  name: "VisibleWithinTime",
  props: {
    start: {
      type: String,
      required: true
    },
    end: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      nowDate: null,
      startDate: null,
      endDate: null,
    }
  },
  mounted() {
    this.nowDate = moment.utc();
    this.startDate = moment.utc(this.start);
    this.endDate = moment.utc(this.end);
  },
}
</script>

<template>
  <slot v-if="this.nowDate?.isAfter(this.startDate) && this.nowDate?.isBefore(this.endDate)"></slot>
  <template v-else></template>
</template>
