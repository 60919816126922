<template>
  <li :id="customId" :tabindex="index" role="radio" :aria-checked="selected"
    class="group relative rounded-lg shadow-sm cursor-pointer focus:outline-none focus:shadow-outline-blue"
    @click="select">
    <div v-bind:class="[selected ? 'border-blue-500' : 'border-transparent']"
      class="rounded-lg border border-gray-300 bg-white dark:bg-gray-700 px-6 py-4 sm:flex sm:justify-between sm:space-x-4">
      <div class="flex items-center space-x-0">
        <div class="flex-shrink-0 flex items-center hidden">
          <span aria-hidden="" class="form-radio text-blue-600 dark:text-blue-300 group-focus:bg-red-500"></span>
        </div>
        <div class="text-sm leading-5">
          <div class="inline-flex items-center space-x-2">
            <p class="block font-medium text-gray-900 dark:text-gray-100">
              {{ flavor.alias ?? flavor.name }}
            </p>
            <span v-if="flavor.ip4 === 0"
              class="inline-flex items-center rounded-md bg-purple-100 dark:bg-purple-700 px-1.5 py-0.5 text-xs font-medium text-purple-700 dark:text-purple-200">
              {{ $t('server.ipv6_badge') }}
            </span>
            <span v-if="flavor.meta?.exclusive_offer"
              class="inline-flex items-center rounded-md bg-black px-1.5 py-0.5 text-xs font-medium text-white">
              🔥 BLACK WEEKEND {{ flavor.meta?.saving_percent ? `- SAVE ${flavor.meta?.saving_percent}%` : '' }} 🤑
            </span>
          </div>
          <div class="text-gray-500 dark:text-gray-300">
            <span class="block sm:inline">
              {{ flavor.cores }} {{ flavor.cores === 1 ? 'vCore' : 'vCores' }}
            </span>
            <span class="hidden sm:inline sm:mx-1">&middot;</span>
            <span class="block sm:inline">{{ Math.floor(flavor.memory / 1000) }} GB RAM</span>
            <span class="hidden sm:inline sm:mx-1">&middot;</span>
            <span class="block sm:inline">{{ flavor.disk }} GB SSD</span>
            <span class="hidden sm:inline sm:mx-1">&middot;</span>
            <span class="block sm:inline">{{ flavor.traffic / 1000 }} TB Bandwidth</span>
            <template v-if="flavor.network > 100">
              <span class="hidden sm:inline sm:mx-1">&middot;</span>
              <span class="block sm:inline">{{ flavor.network / 1000 }} GBit/s Network</span>
            </template>
          </div>
        </div>
      </div>
      <div class="mt-2 flex text-sm leading-5 space-x-1 sm:mt-0 sm:block sm:space-x-0 sm:text-right">
        <div class="font-medium text-gray-900 dark:text-gray-100">&euro; {{ priceString }}</div>
        <div class="text-gray-500 dark:text-gray-300 text-xs">/ {{ $t('global.month') }}</div>
      </div>
    </div>
  </li>
</template>

<script>
import Dinero from 'dinero.js';

export default {
  name: 'FlavorSelectionCard',

  model: {
    prop: 'selection',
    event: 'change',
  },

  props: {
    flavor: {
      required: true
    },
    selectedFlavor: {},
    index: {}
  },

  data() {
    return {
      selection: this.flavor,
    }
  },

  computed: {
    selected() {
      return this.selectedFlavor.name && this.selectedFlavor.name === this.selection.name
    },

    customId() {
      return "radiogroup-option-" + this.index;
    },

    priceString() {
      if (this.flavor) {
        let price = parseFloat(this.flavor.monthly_gross_price)
        price = price >= 0 ? price : 0

        return Dinero({ amount: price, currency: 'EUR' })
          .setLocale('de-DE')
          .toFormat('0,0.00')
      }
    },
  },

  methods: {
    select() {
      this.$emit('selectFlavor', this.flavor)
    }
  }
}
</script>
