<template>
  <transition>
    <Modal id="extend-server-runtime" :minWidthClass="'max-w-2xl'" :open="isOpen" @close="$emit('close')">
      <template v-slot:icon>
        <svg class="h-6 w-6 text-blue-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
            clip-rule="evenodd"></path>
        </svg>
      </template>
      <template v-slot:title>
        <div class="text-center md:text-left mt-4 md:mt-0">
          {{ $t('server.extend_server_runtime_modal.title') }}
        </div>
      </template>
      <template v-slot:subtitle>
        <p class="text-sm text-gray-500 dark:text-gray-300 text-center md:text-left"
          v-html="$t('server.extend_server_runtime_modal.description', { serverName: server.name })"></p>
      </template>
      <template v-slot:default>
        <Loader v-if="isLoading" />
        <template v-else>
          <div class="bg-white dark:bg-gray-800 rounded-lg overflow-hidden border dark:border-gray-700">
            <PricingTable v-model="selectedRuntime" :options="runtimes" />
          </div>

          <div v-if="selectedRuntime && server.power_state === 'stopped'" class="mt-6 flex items-center">
            <Switch v-model="startAfterExtension"
              class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center ring-0">
              <span class="sr-only">{{ $t('server.extend_server_runtime_modal.start_server') }}</span>
              <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md "></span>
              <span aria-hidden="true"
                :class="[startAfterExtension ? 'bg-blue-600' : 'bg-gray-200', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']"></span>
              <span aria-hidden="true"
                :class="[startAfterExtension ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']"></span>
            </Switch>
            <span class="ml-3">
              <span class="text-xs md:text-sm font-medium text-gray-900 dark:text-gray-100">{{
                $t('server.extend_server_runtime_modal.start_server') }}</span>
              <br>
              <span class="text-xs md:text-sm text-gray-400">{{
                $t('server.extend_server_runtime_modal.start_server_description')
              }}</span>
            </span>
          </div>

          <div v-if="selectedRuntime && !server.auto_extend" class="mt-6 inline-flex gap-x-6">
            <Switch v-model="autoExtend" class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              <span class="sr-only">
                {{ $t('server.extend_server_runtime_modal.enable_auto_extension') }}
              </span>
              <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-white"></span>
              <span aria-hidden="true" :class="[autoExtend ? 'bg-blue-600' : 'bg-gray-200', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']"></span>
              <span aria-hidden="true" :class="[autoExtend ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']"></span>
            </Switch>
            <div>
              <p class="mb-1 text-sm font-medium text-gray-900 dark:text-gray-100">
                {{ $t('server.extend_server_runtime_modal.enable_auto_extension') }}
              </p>
              <p class="text-sm text-gray-500">
                {{ $t('server.extend_server_runtime_modal.enable_auto_extension_description') }}
              </p>
            </div>
          </div>

          <Extension v-if="selectedRuntime" :amount="selectedRuntime.gross_money" :from-date="selectedRuntime.from_date"
            :to-date="selectedRuntime.to_date" class="mt-8" />
        </template>
      </template>
      <template v-slot:footer>
        <button-spinner :disabled="isSubmit" :btnDisable="!selectedRuntime" @click="submit()"
          class="disabled:opacity-75 focus:outline-none focus:ring-0 focus:ring-offset-0 bg-blue-600 w-full inline-flex items-center justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm">
          <template v-if="selectedRuntime">
            {{ $t('server.extend_server_runtime_modal.pay_now', [selectedRuntime.gross_money]) }}
          </template>
          <template v-else>
            {{ $t('server.extend_server_runtime_modal.select_extension_period') }}
          </template>
        </button-spinner>
        <button @click="$emit('close')" type="button"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 focus:outline-none focus:ring-0 focus:ring-offset-0 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          {{ $t('global.dismiss') }}
        </button>
      </template>
    </Modal>
  </transition>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import PricingTable from '@/components/v2/PricingTable'
import { Switch } from '@headlessui/vue'
import { useStore } from 'vuex'
import Modal from '@/components/v2/Modal'
import Loader from '@/components/loader/Loader'
import ButtonSpinner from '@/components/form/ButtonSpinner'
import Extension from '@/components/v2/Extension'

const props = defineProps({
  server: {
    type: Object,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['close', 'extendSuccess'])

const store = useStore()

const isSubmit = ref(false)
const isLoading = ref(true)
const selectedRuntime = ref(null)
const startAfterExtension = ref(true)
const autoExtend = ref(false)

const runtimes = computed(() => store.getters.runtimes)

const submit = () => {
  isSubmit.value = true
  const data = {
    serverId: props.server.uuid,
    form: {
      months: selectedRuntime.value.months,
      startAfterExtension: startAfterExtension.value,
    }
  }

  store.dispatch('serverExtend', data).then(() => {
    store.dispatch('getServer', props.server.uuid)
    isSubmit.value = false
    emit('extendSuccess')
  })

  // Handle auto extension
  store.dispatch('serverSettings', { serverId: props.server.uuid, form: { auto_extend: autoExtend.value } })
}

onMounted(() => {
  store.dispatch('getServerRuntimes', {
    serverId: props.server.uuid,
    tab: 'runtimes'
  }).then(() => {
    isLoading.value = false
    autoExtend.value = !!props.server.auto_extend
    selectedRuntime.value = runtimes.value[0]
  })
})
</script>
